@charset "UTF-8";
/*------------------------------------*\
  rapworldmap.com - main.css
  @author Crucifix Arnaud (hello@crucifixarnaud.com)
\*------------------------------------*/
/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*------------------------------------*\
  > Functions
  Usefull functions use every where in the applications
\*------------------------------------*/
/**
 * [parseInt Return an integer from a value with a unit]
 * @param  {number} $n [A number]
 * @return {int}    [The related integer of the given ($n) number]
 */
/**
 * [color-diff Return a map of the difference between two color]
 * @param  {color} $color-a [Any valid css color]
 * @param  {color} $color-b [Any valid css color]
 * @return {map}          [A map of the diff hue, saturation, ligthness between the two color]
 */
/**
 * [z Look for the given argument in a layers map to see if it is mapped to a z-index value]
 * @param  {string} $layer [Layer to search for]
 * @return {int} [Z-index value]
 */
/*------------------------------------*\
  > Variables
\*------------------------------------*/
/*------------------------------------*\
  > Mixins
  Generic and common mixins
\*------------------------------------*/
/**
 * [rem Return good rem value related to a given function, and add a fallback with pixel]
 * @param  {string} $property [Any property accepting rem/px value]
 * @param  {number} $values   [A number with the wanted final value in px]
 */
/**
 * [Vertical Center]
 * @param  {string} $position [Position type of the element] (default: relative)
 */
/**
 * [Horizontal Center]
 * Horizontaly align item
 * @param  {string} $position [Position type of the element] (default: relative)
 */
/**
 * [Center Align]
 * @param  {string} $position [Position type of the element] (default: relative)
 */
/**
 * [triangle]
 * Display a triangle (use for carret)
 * @param  {width (+ unit)} $size [Size of the triangle]
 * @param  {string} $color [Color of the triangle]
 * @param  {string} $direction [Direction of the triangle, top, right, down, left]
 */
/**
 * [debug-map Output content of a sass map for debug purpose]
 * @param  {map} $map [The map to debug]
 */
/*------------------------------------*\
  > Fonts
\*------------------------------------*/
@font-face {
  font-family: 'liberation';
  src: url("../fonts/LiberationSans/LiberationSans-Regular-webfont.eot");
  src: url("../fonts/LiberationSans/LiberationSans-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/LiberationSans/LiberationSans-Regular-webfont.woff") format("woff"), url("../fonts/LiberationSans/LiberationSans-Regular-webfont.ttf") format("truetype"), url("../fonts/LiberationSans/LiberationSans-Regular-webfont.svg#liberation_sansregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'liberation';
  src: url("../fonts/LiberationSans/LiberationSans-Bold-webfont.eot");
  src: url("../fonts/LiberationSans/LiberationSans-Bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/LiberationSans/LiberationSans-Bold-webfont.woff") format("woff"), url("../fonts/LiberationSans/LiberationSans-Bold-webfont.ttf") format("truetype"), url("../fonts/LiberationSans/LiberationSans-Bold-webfont.svg#liberation_sansregular") format("svg");
  font-weight: bold;
  font-style: normal; }

/*------------------------------------*\
  > Typograpphy
  Define all typography global rules heres
  (fontface are handle in fonts.scss file)
\*------------------------------------*/
html {
  font-size: 62.5%; }

body {
  font-family: liberation, sans-serif;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400; }

a {
  color: #ffd700;
  text-decoration: none; }
  a:hover, a:focus, a:active {
    text-decoration: underline;
    outline-color: #ffd700; }

abbr[title] {
  border-bottom: 0; }

/*------------------------------------*\
  > Layout
  All default layout styles
\*------------------------------------*/
* {
  box-sizing: border-box; }

body {
  background: #000;
  height: 100%;
  width: 100%; }

.main-title {
  margin: 0; }

/*------------------------------------*\
  > Button
  All style & mixins for generic buttons
\*------------------------------------*/
.button,
[class*="button--"] {
  display: inline-block;
  border-radius: 2px;
  border: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer; }
  .button:hover, .button:focus, .button:active,
  [class*="button--"]:hover,
  [class*="button--"]:focus,
  [class*="button--"]:active {
    text-decoration: none; }


.button.disable,
[class*="button--"].disable {
  cursor: not-allowed;
  pointer-events: none; }

.button, .button--primary, .button--danger {
  font-size: 15px;
  font-size: 1.5rem;
  padding: 0 1.5rem;
  line-height: 1.8; }

.button--primary--md {
  font-size: 15px;
  font-size: 1.5rem;
  padding: 0 3rem;
  line-height: 2.8; }


[class*='button--primary'] {
  position: relative;
  background-color: #ffd700;
  background-image: linear-gradient(to right, #ffd700, #ffeaa3 100%);
  background-size: 400% 400%;
  color: #1b2b34;
  transition: background-position 0.5s ease-in-out, background-color 0.2s ease-in-out; }
  
  [class*='button--primary']:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .5;
    background: -250px center no-repeat;
    border-radius: 4px;
    background-clip: padding-box;
    transition: all .5s ease-in-out;
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0) 25%, #fff4c2 25%, #fff4c2 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 60%, #fff4c2 60%, #fff4c2 70%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 76%, #fff4c2 76%, #fff4c2 80%, rgba(255, 255, 255, 0) 80%); }
  
  [class*='button--primary']:hover:not(.disable),
  [class*='button--primary']:focus:not(.disable) {
    outline: none;
    background-position: 98% 50%;
    transition: background-position 1s ease-in-out, background-color 0.2s ease-in-out, color .2s ease-in-out;
    background-color: #ffeaa3;
    color: #000; }
    
    [class*='button--primary']:hover:not(.disable):after,
    [class*='button--primary']:focus:not(.disable):after {
      background-position: 200px center; }
  
  [class*='button--primary']:active:not(.disable) {
    background-image: none;
    background-color: #fad300; }


[class*='button--danger'] {
  position: relative;
  background-color: #db3737;
  color: #fff;
  transition: background-color 0.2s ease-in-out; }
  
  [class*='button--danger']:hover:not(.disable),
  [class*='button--danger']:focus:not(.disable) {
    outline: none;
    background-color: #bd2222; }
  
  [class*='button--danger']:active:not(.disable) {
    background-image: none;
    background-color: #921a1a; }

.button--link {
  border: 0;
  background-color: transparent;
  color: #ffd700;
  border-radius: 0;
  padding: 0; }
  .button--link:hover, .button--link:focus, .button--link:active {
    text-decoration: underline; }
  .button--link:hover {
    outline: none; }
  .button--link:focus {
    outline-color: #ffd700; }

.button--link--large {
  border: 0;
  background-color: transparent;
  color: #ffd700;
  border-radius: 0;
  padding: 0;
  font-size: 1.6rem; }
  .button--link--large:hover, .button--link--large:focus, .button--link--large:active {
    text-decoration: underline; }
  .button--link--large:hover {
    outline: none; }
  .button--link--large:focus {
    outline-color: #ffd700; }

/*------------------------------------*\
  > Navigation
  Resuable navigation components
\*------------------------------------*/
.menu,
.submenu {
  list-style-type: none;
  padding: 0; }

.menu__item__button,
.submenu__item__button {
  pointer-events: auto;
  font-size: 14px;
  font-size: 1.4rem;
  border: 0;
  background-color: transparent;
  color: #fff;
  transition: color 0.2s ease-in-out; }
  .menu__item__button:hover:not(.disable), .menu__item__button:focus:not(.disable),
  .submenu__item__button:hover:not(.disable),
  .submenu__item__button:focus:not(.disable) {
    color: #e6e6e6; }
  .menu__item__button:hover:not(.disable),
  .submenu__item__button:hover:not(.disable) {
    outline: none; }
  .menu__item__button:focus:not(.disable),
  .submenu__item__button:focus:not(.disable) {
    outline-color: #fff; }
  .menu__item__button:active:not(.disable),
  .submenu__item__button:active:not(.disable) {
    color: #cccccc; }
  .menu__item__button:hover:not(.disable), .menu__item__button:focus:not(.disable), .menu__item__button:active:not(.disable),
  .submenu__item__button:hover:not(.disable),
  .submenu__item__button:focus:not(.disable),
  .submenu__item__button:active:not(.disable) {
    outline: none; }

.menu__item__button:hover .button__label,
.menu__item__button:focus .button__label,
.menu__item__button:active .button__label {
  text-decoration: underline; }

/*------------------------------------*\
  > Form
\*------------------------------------*/
[type="text"],
[type="email"],
[type="password"],
[type="date"],
[type="number"],
textarea {
  display: block;
  padding: 5px;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  width: 100%;
  font-size: 14px;
  font-size: 1.4rem;
  background: #111a20; }
  [type="text"]:focus,
  [type="email"]:focus,
  [type="password"]:focus,
  [type="date"]:focus,
  [type="number"]:focus,
  textarea:focus {
    outline: none;
    box-shadow: 0 2px 0 #ffd700; }

textarea {
  min-height: 250px; }

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked,
[type="radio"]:not(:checked),
[type="radio"]:checked {
  opacity: 0;
  position: absolute; }

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label,
[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer; }

[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before,
[type="radio"]:not(:checked) + label:before,
[type="radio"]:checked + label:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: #111a20;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer; }

/* Fake Checked mark aspect */
[type="checkbox"]:not(:checked) + label:hover:after,
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after,
[type="radio"]:not(:checked) + label:hover:after,
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 10px;
  top: 6px;
  left: 5px;
  color: #ffd700;
  transition: all .2s; }

[type="checkbox"]:not(:checked) + label:after,
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0); }

/* Hover style */
[type="checkbox"]:not(:checked) + label:hover:after,
[type="checkbox"]:focus:not(:checked) + label:after,
[type="radio"]:not(:checked) + label:hover:after,
[type="radio"]:focus:not(:checked) + label:after {
  opacity: 1;
  transform: scale(1);
  background: #2c4756; }

[type="checkbox"]:checked + label:after,
[type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
  background: #ffd700; }

/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before,
[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
  background-color: #16232a; }

[type="checkbox"]:disabled + label,
[type="radio"]:disabled + label {
  color: gray; }

[type="checkbox"]:disabled + label,
[type="checkbox"]:disabled + label:before,
[type="radio"]:disabled + label,
[type="radio"]:disabled + label:before {
  cursor: not-allowed; }

/* accessibility */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  border-radius: 3px; }

[type="checkbox"]:not(:checked) + label:hover:after,
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  border-radius: 2px; }

[type="radio"]:not(:checked) + label:before,
[type="radio"]:checked + label:before {
  border-radius: 10px; }

[type="radio"]:not(:checked) + label:hover:after,
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:after {
  border-radius: 5px; }

.input--file {
  font-size: 16px;
  font-size: 1.6rem; }

.input--range {
  box-sizing: border-box;
  line-height: 1;
  background-color: transparent;
  cursor: pointer;
  -webkit-appearance: none;
  width: 100%; }
  .input--range::-webkit-slider-thumb {
    -webkit-appearance: none; }
  .input--range::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent; }
  .input--range:focus {
    outline: none; }
  .input--range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1.5em;
    height: 1.5em;
    margin-top: 0;
    background-color: #ffd700;
    border-radius: 1em;
    border: 1px solid #ffd700;
    cursor: pointer;
    transition: all .1s ease-in-out; }
  .input--range::-moz-range-thumb {
    width: 1.5em;
    height: 1.5em;
    margin-top: 0;
    background-color: #ffd700;
    border-radius: 1em;
    border: 1px solid #ffd700;
    cursor: pointer;
    transition: all .1s ease-in-out; }
  .input--range::-ms-thumb {
    width: 1.5em;
    height: 1.5em;
    margin-top: 0;
    background-color: #ffd700;
    border-radius: 1em;
    border: 1px solid #ffd700;
    cursor: pointer;
    transition: all .1s ease-in-out; }
  .input--range:focus::-webkit-slider-thumb, .input--range:hover::-webkit-slider-thumb {
    background-color: #ffe766;
    border-color: #ffe766; }
  .input--range:focus::-moz-range-thumb, .input--range:hover::-moz-range-thumb {
    background-color: #ffe766;
    border-color: #ffe766; }
  .input--range:focus::-ms-thumb, .input--range:hover::-ms-thumb {
    background-color: #ffe766;
    border-color: #ffe766; }
  .input--range:active::-webkit-slider-thumb {
    background-color: #2c4756;
    border-color: #2c4756; }
  .input--range:active::-moz-range-thumb {
    background-color: #2c4756;
    border-color: #2c4756; }
  .input--range:active::-ms-thumb {
    background-color: #2c4756;
    border-color: #2c4756; }
  .input--range::-webkit-slider-runnable-track {
    width: 100%;
    cursor: pointer;
    height: 1em;
    border-bottom: 4px solid #111a20;
    background-color: transparent; }
  .input--range::-moz-range-track {
    width: 100%;
    cursor: pointer;
    height: 1em;
    border-bottom: 4px solid #111a20;
    background-color: transparent; }
  .input--range::-ms-track {
    background: transparent;
    border-color: transparent;
    color: transparent; }

.field {
  position: relative;
  margin: 0 0 .5em; }

.field__label {
  display: block;
  margin-bottom: .4em;
  font-size: 16px;
  font-size: 1.6rem; }

.field__label--optional {
  font-size: 12px;
  font-size: 1.2rem;
  vertical-align: middle; }

.field--inline {
  display: inline-block;
  margin-right: 1em; }
  .field--inline:last-child {
    margin-right: 0; }

.field-group {
  margin: 1.5em 0;
  padding-left: .5em; }
  .field-group:first-of-type {
    margin-top: 0; }
  .field-group:last-of-type {
    margin-bottom: 0; }

.field-group__title {
  margin: 0 0 0.5em -0.5em;
  font-size: 18px;
  font-size: 1.8rem;
  color: #ffd700; }

.errors {
  font-size: 15px;
  font-size: 1.5rem;
  margin-bottom: 1em; }

.error {
  color: #e52323; }

.error p {
  margin: 0; }

.success {
  background: #3eb36f;
  padding: .5em 1em;
  margin-bottom: 2em;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 0;
  border-radius: 2px; }

.field-error {
  display: block;
  margin-top: .5em;
  color: #e52323;
  font-size: 12px;
  font-size: 1.2rem; }

/*------------------------------------*\
  > Table
\*------------------------------------*/
.table {
  max-width: 1200px;
  width: 100%; }

.table__row {
  background: #16232a; }
  .table__row:nth-child(odd) {
    background: #121d23; }
  .table__row:hover, .table__row:focus, .table__row:nth-child(odd):hover, .table__row:nth-child(odd):focus {
    background: #20333e; }

.table__cell {
  padding: .5em 1em;
  font-size: 13px;
  font-size: 1.3rem;
  max-width: 200px; }

.table__cell--loader {
  height: 25vh;
  text-align: center;
  position: relative;
  width: 100%; }

.table__head__row {
  background: #192831; }

.table__head__cell {
  padding: 1em;
  text-align: left;
  font-size: 16px;
  font-size: 1.6rem;
  text-transform: uppercase; }

/*------------------------------------*\
  > Thumbnails
  All style for thumbnails
\*------------------------------------*/
.thumbnail {
  display: block;
  overflow: hidden;
  background: #1b2b34;
  border: 4px solid #ffd700; }
  @media screen and (max-width: 767px) {
    .thumbnail {
      height: 100px;
      width: 100px;
      border-radius: 50px; } }
  @media screen and (min-width: 768px) {
    .thumbnail {
      height: 200px;
      width: 200px;
      border-radius: 100px; } }

.thumbnail__picture {
  display: block;
  width: 100%; }

.thumbnail--small {
  display: block;
  overflow: hidden;
  background: #1b2b34;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  border: 2px solid #ffd700; }

/*------------------------------------*\
  > Loader
\*------------------------------------*/
.no-js .loader {
  display: none; }

.loader {
  z-index: 1750;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: #16232a;
  opacity: .1;
  visibility: hidden;
  transition: opacity .4s ease, visibility .4s ease; }

.loader.active {
  visibility: visible;
  opacity: 1; }

.loader__spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.loader__spin:before,
.loader__spin:after {
  content: "";
  position: absolute;
  top: -50px;
  left: -50px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 5px solid transparent;
  border-top-color: #fff; }

.loader__spin:before {
  z-index: 1751;
  animation: spin 1.2s infinite linear; }

.loader__spin:after {
  border: 5px solid #121d23; }

.loader--atlas {
  background: #000; }

.loader--atlas .loader__spin:after {
  border: 5px solid #222; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/*------------------------------------*\
  > Dropdown
\*------------------------------------*/
.dropdown__button {
  color: #fff;
  cursor: pointer;
  position: relative;
  padding-right: 2rem;
  transition: color .2s ease;
  font-size: 14px;
  font-size: 1.4rem; }
  .dropdown__button:hover, .dropdown__button:focus, .dropdown__button:active {
    color: #ffd700;
    text-decoration: none; }
    .dropdown__button:hover:after, .dropdown__button:focus:after, .dropdown__button:active:after {
      border-top-color: #ffd700; }
  .dropdown__button:after {
    transition: color 0.2s ease, transform 0.3s cubic-bezier(1, 0, 0, 1), margin 0.3s cubic-bezier(1, 0, 0, 1);
    content: '';
    width: 0;
    height: 0;
    border: 0.4rem solid transparent;
    border-top: 0.4rem solid #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin-top: 1px; }

.dropdown__button.open:after {
  transform-origin: 50%;
  transform: translateY(-50%) rotate(-180deg);
  margin-top: -2px; }

.dropdown__content {
  min-width: 20rem;
  position: absolute;
  margin-top: .5rem;
  margin-left: -1rem;
  padding: 1rem;
  font-size: 14px;
  font-size: 1.4rem;
  background: #16232a;
  border: 1px solid #000;
  z-index: 2000; }

.dropdown__list {
  list-style-type: none;
  padding: 0;
  margin: 0; }

.dropdown__item__item {
  padding: 0;
  margin: 0; }

.dropdown__item__link {
  color: #fff;
  display: block;
  width: 100%;
  padding: .6rem;
  position: relative;
  cursor: pointer; }
  .dropdown__item__link:hover, .dropdown__item__link:focus, .dropdown__item__link:active {
    background: #121d23;
    text-decoration: none; }

.dropdown__item__link.active {
  color: #ffd700; }
  .dropdown__item__link.active:after {
    display: block;
    position: absolute;
    content: "\2713";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .5em; }

/*------------------------------------*\
  > Page Admin
  Specific style for page admin
\*------------------------------------*/
.page-admin {
  background-color: #18252d; }

.link--back-to {
  font-size: 16px;
  font-size: 1.6rem;
  display: inline-block;
  margin-bottom: 1em; }
  .link--back-to:hover, .link--back-to:focus, .link--back-to:active {
    text-decoration: none;
    color: #ffeaa3; }

.admin-header {
  background-color: #18252d; }

.main-title,
.page__title,
.admin__title {
  display: inline-block;
  vertical-align: middle; }

.main-title {
  font-size: 24px;
  font-size: 2.4rem; }

@media screen and (max-width: 767px) {
  .admin-header .main-title {
    display: block; } }

.main-logo {
  display: block; }

.main-title a {
  color: #fff; }
  .main-title a:hover, .main-title a:focus, .main-title a:active {
    text-decoration: none; }

.page__title,
.admin__title {
  font-size: 18px;
  font-size: 1.8rem; }

.page__title:before {
  content: ' › ';
  color: #ffd700; }

.admin__title {
  color: #fff;
  margin-right: .2em; }
  @media screen and (max-width: 767px) {
    .admin__title {
      margin-left: 1em; } }

.page__title .artist-name {
  color: #ffd700; }

.admin-content {
  background-color: #1b2b34;
  padding: 2em; }
  @media screen and (min-width: 1160px) {
    .admin-content {
      max-width: calc(100% - 8em);
      margin-left: 4em;
      margin-bottom: 4em; } }

.admin-content__title {
  margin: 0;
  font-size: 24px;
  font-size: 2.4rem; }

.field__thumbnail {
  margin-right: 1em;
  margin-bottom: 1em; }

.form {
  max-width: 500px; }

.filters-box {
  margin-left: 2rem;
  margin-bottom: 1em; }
  @media screen and (min-width: 480px) {
    .filters-box {
      display: inline-block;
      margin-bottom: 0; } }

.filters-box__title {
  display: inline-block;
  font-size: 16px;
  font-size: 1.6rem;
  margin-right: 1em; }

@media screen and (min-width: 480px) {
  .filters-box .field-group {
    display: inline-block; } }

@media screen and (max-width: 479px) {
  .filters-box .field--inline {
    display: block;
    margin-bottom: 1em; } }

@media screen and (min-width: 768px) {
  .filters-box .field--inline {
    margin-left: 1em; }
    .filters-box .field--inline:last-child {
      margin-left: 0; } }

.artist-total {
  font-size: 16px;
  font-size: 1.6rem; }

.table--artist {
  margin-top: 1em;
  position: relative; }
  @media screen and (max-width: 1159px) {
    .table--artist {
      width: 100%; } }

@media screen and (max-width: 767px) {
  .table__head__cell {
    font-size: 12px;
    font-size: 1.2rem; } }

@media screen and (max-width: 1159px) {
  .table__head__cell,
  .table__cell {
    display: inline-block;
    width: 25%; } }

@media screen and (max-width: 1159px) {
  .table__cell--action {
    width: 100%;
    display: block; } }

.table__cell--action [class^='button'] {
  margin-left: .5em; }
  .table__cell--action [class^='button']:first-child {
    margin-left: 0; }

@media screen and (max-width: 1159px) {
  .table__head__cell--publication-date,
  .table__cell--publication-date {
    display: none; } }

.table__cell.unpublished {
  color: #e52323; }

/*------------------------------------*\
  > Page Errors
  Specific style for errors page
\*------------------------------------*/
.errors-container {
  padding: 2em;
  font-size: 18px;
  font-size: 1.8rem; }

.error__content {
  padding-left: 1em; }
